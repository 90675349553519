import React, { useState, useEffect } from "react";
import { AiOutlineMail } from "react-icons/ai";
import OtpInput from "react-otp-input";
import authAPI from "../../axios-setup/auth-service";
import toast, { Toaster } from "react-hot-toast";
import { sendOTP, verifyOTP, updateUser } from "../../redux/slices/auth";
import { useSelector, useDispatch } from "react-redux";

const ResendOTP = ({ resendOTP }) => {
  const [timer, setTimer] = React.useState(30);
  React.useEffect(() => {
    setTimeout(() => {
      if (timer > 0) setTimer(timer - 1);
    }, 1000);
  }, [timer]);
  return (
    <div>
      {timer > 0 ? (
        <span className="text-sm">Resend OTP in {timer} sec</span>
      ) : (
        <span
          onClick={() => {
            resendOTP();
            setTimer(30);
          }}
          className="text-sm cursor-pointer font-semibold"
        >
          Resend OTP
        </span>
      )}
    </div>
  );
};

const Login = ({ afterLogin, setOpenLoginModal }) => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [registered, setRegistered] = useState(null);
  const [otpVerified, setOtpVerified] = useState(false);
  const [name, setName] = useState("");

  const dispatch = useDispatch();

  const regexExp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

  useEffect(() => {
    const phoneLC = localStorage.getItem("purchase-by-contact");
    if (phoneLC) {
      setPhone(phoneLC);
    }
  }, []);

  const {
    auth: { loading },
  } = useSelector((state) => {
    return {
      auth: state.auth,
    };
  });
  const CrossIcon = () => {
    if (!setOpenLoginModal) return null;

    return (
      <button
        className="self-end text-xl -mt-3 md:-mt-5"
        onClick={() => {
          if (otpVerified && !registered) {
            toast.error("Please enter your name to continue!");
          } else {
            setOpenLoginModal(false);
          }
        }}
      >
        <span className="">×</span>
      </button>
    );
  };

  const resendOTP = async () => {
    try {
      if (regexExp.test(phone)) {
        await dispatch(sendOTP(phone, toast, setOtpSent));
      } else {
        toast.error("Please enter a valid phone number!");
      }
    } catch (err) {
      console.log(err, "Error");
      toast.error(err.message);
    }
  };

  const afterFullSteps = async () => {
    if (afterLogin) afterLogin();
    if (setOpenLoginModal) setOpenLoginModal();
  };

  const postName = async () => {
    if (name.length === 0) {
      toast.error("Please enter your name!");
      return;
    }
    const res = await authAPI.post("/api/v1/app/mapper", {
      method: "post",
      url: `update-user`,
      data: {
        name: name,
      },
    });

    dispatch(updateUser({ name: name }, toast));

    if (res.data.status == "success") {
      if (afterFullSteps) afterFullSteps();
    } else {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div>
      <div className="rounded-lg justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[99999999999] outline-none focus:outline-none">
        <div className="w-full rounded-lg z-[99999999999]">
          <div className="w-11/12 sm:w-7/12 lg:w-5/12 xl:w-4/12 mx-auto my-28 shadow-lg bg-white p-4 md:p-7 rounded-lg">
            <div className="flex justify-end flex-row">
              <CrossIcon />
            </div>
            <div className="flex flex-col gap-12 xs:gap-16">
              <div className="flex flex-col gap-1">
                <img className="w-9 h-9" src="/leaderboard/logo.png" />
                <span className="text-xl xs:text-2xl tracking-wider font-semibold mt-3">
                  Log In
                </span>
                <span className="text-gray-500 tracking-wider">
                  Start learning today!
                </span>
                <>
                  {otpVerified ? (
                    <>
                      <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="border border-1 px-2 py-3 rounded-lg mt-3 tracking-wider"
                        placeholder="Enter your Full Name"
                      />
                      <button
                        onClick={async () => {
                          await postName();
                        }}
                        className="mt-2 bg-[#7f56d9] text-white px-2 py-3 rounded-lg tracking-wider"
                      >
                        {loading ? "Please Wait..." : "Continue"}
                      </button>
                    </>
                  ) : (
                    <>
                      {!otpSent ? (
                        <>
                          <input
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="border border-1 px-2 py-3 rounded-lg mt-3 tracking-wider"
                            placeholder="Enter your mobile number"
                          />
                          <button
                            onClick={() => {
                              if (regexExp.test(phone)) {
                                dispatch(sendOTP(phone, toast, setOtpSent));
                              } else {
                                toast.error(
                                  "Please enter a valid phone number!"
                                );
                              }
                            }}
                            className="mt-2 bg-[#7f56d9] text-white px-2 py-3 rounded-lg tracking-wider"
                          >
                            {loading ? "Please Wait..." : "Login"}
                          </button>
                        </>
                      ) : (
                        <>
                          <OtpInput
                            value={otp}
                            className="my-3"
                            onChange={(otp) => setOtp(otp)}
                            numInputs={4}
                            separator={
                              <span style={{ width: "1.5rem" }}></span>
                            }
                            isInputNum={true}
                            shouldAutoFocus={true}
                            inputStyle={{
                              border: "1px solid gray",
                              borderRadius: "8px",
                              width: "48px",
                              height: "40px",
                              fontSize: "20px",
                              color: "#000",
                              fontWeight: "400",
                              caretColor: "blue",
                            }}
                            focusStyle={{
                              border: "1px solid #CFD3DB",
                              outline: "none",
                            }}
                          />
                          <div className="mb-2 flex justify-between items-center">
                            <ResendOTP resendOTP={resendOTP} />
                          </div>

                          <button
                            onClick={() => {
                              setOtpSent(false);
                            }}
                            className="mt-2 bg-tn-light-bg text-tn-blue px-2 py-3 rounded-lg tracking-wider"
                          >
                            Change Mobile Number
                          </button>
                          <button
                            onClick={async () => {
                              dispatch(
                                verifyOTP(
                                  { phone, otp },
                                  toast,
                                  afterFullSteps,
                                  setRegistered,
                                  setOtpVerified
                                )
                              );
                            }}
                            className="mt-2 bg-[#7f56d9] text-white px-2 py-3 rounded-lg tracking-wider"
                          >
                            {loading ? "Please Wait..." : "Verify"}
                          </button>
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
              <div className="flex flex-col xs:flex-row gap-0 sm:gap-2 justify-between items-center text-xs md:text-sm tracking-wider">
                <span className="text-gray-500">© ToppersNotes 2023</span>
                <span className="text-gray-500 flex flex-row items-center gap-1">
                  <AiOutlineMail /> hello@toppersnotes.com
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-[102] bg-black"></div>
    </div>
  );
};

export default Login;
